<route>
{
  "meta": {
    "permission": [
      "customer.view_customer"
    ]
  }
}
</route>

<template>
  <v-container fluid>
    <v-row row wrap>
      <v-col cols="12" md="3">
        <ValidationProvider
          vid="first_name"
          :name="$t('first_name')"
          v-slot="{ errors }"
        >
          <v-text-field
            v-model="form.first_name"
            outlined
            :error-messages="errors[0]"
            :label="$t('first_name')"
            class="secondary--text"
          ></v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="3">
        <ValidationProvider
          vid="last_name"
          :name="$t('last_name')"
          v-slot="{ errors }"
        >
          <v-text-field
            v-model="form.last_name"
            outlined
            :error-messages="errors[0]"
            :label="$t('last_name')"
            class="secondary--text"
          ></v-text-field>
        </ValidationProvider>
      </v-col>
    </v-row>
    <i-table
      :title="$t('customersReport')"
      :headers="headers"
      app="customer.customer"
      api="customer"
      :opt="{
        company_id: this.firstCompany,
        first_name: this.form.first_name,
        last_name: this.form.last_name
      }"
      dontCreate
      dontRemove
      dontSearch
    >
      <template v-slot:extraToolbar>
        <v-divider class="mx-4" inset vertical></v-divider>
        <i-btn
          text
          :small="$vuetify.breakpoint.smAndDown"
          icon="fa-file-excel"
          :title="$t('download', { value: $tc('customer', 2) })"
          color="text-dark"
          @click="getCustomersExcel()"
        >
        </i-btn>
      </template>
    </i-table>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      firstCompany: 0,
      form: {
        first_name: '',
        last_name: ''
      }
    }
  },
  created() {
    this.setCompany()
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('company_name'),
          value: 'company_name'
        },
        {
          text: this.$t('first_name'),
          value: 'first_name'
        },
        {
          text: this.$t('last_name'),
          value: 'last_name'
        },
        {
          text: this.$tc('email', 1),
          value: 'email'
        },
        {
          text: this.$t('phone_number'),
          value: 'phone'
        },
        {
          text: this.$tc('level', 1),
          value: 'level.name'
        }
      ]
    },
    ...mapGetters({
      myCompanies: 'session/myCompanies'
    })
  },
  methods: {
    getCustomersExcel() {
      this.downloadFile({
        api: 'customer',
        method: 'excel',
        filename: 'Customers',
        action: 'download',
        ext: 'xlsx',
        opt: {
          pk: 1,
          params:
            '?first_name=' +
            this.form.first_name +
            '&last_name=' +
            this.form.last_name
        }
      })
    },
    /**
     * setCompany
     * Método para asignar la compañía a la que pertenece el usuario al
     * filtro de la empresa de la tabla
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    setCompany() {
      if (this.myCompanies.length > 0) {
        this.firstCompany = this.myCompanies[0].id
      }
    }
  }
}
</script>
